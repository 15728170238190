import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import {
    fetchEstilos,
    fetchMarcas,
    fetchModelos,
    fetchStates,
    fetchCities,
} from "./../../actions";

import { UrlsClassiUtils } from "../../resources/methods/UrlsClassiUtils";

// Components
import {
    Container,
    VitrineTitulo,
    LogoVitrine,
    BannerContainer,
} from "./../../components/commons/theme-styled";

import GPTComponent from "./../../components/gpt/GPT";
import Loading from "../../components/loading/loading";
import Topbar from "./../../components/topbar/topbar";
import { Helmet } from "react-helmet";
import BlogFeed from "../../components/blog-feed/blog-feed";

// Images
import LogoCarros from "../../resources/svg/logos/logo-classi-motos.svg";
import BgCarros from "../../resources/svg/bg-vitrines/fundo-motos.png";
import ClassiLogo from "./../../resources/svg/logo-classi.svg";

// Colors
import { Theme } from "../../components/commons/vitrine-colors";

// Styles
import {
    FilterContainer,
    FilterLabel,
    Filter,
    BuscaBtn,
    FilterSelect,
    FilterSelectLocal,
    FilterCodigo,
} from "./../../components/commons/filter-styled";

import { StringUtils } from "../../resources/methods/StringUtils";

const vitrineType = process.env.VITRINE_TYPE.toLowerCase();

class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            categorias: [],
            estados: [],
            cidades: [],

            estilo: "",
            ufEstado: "", //UF padrão (Se utilizado a mesma string respondida pelo bank-end para UF e Cidade, contempla preset de filtro para filtro vazio)
            cidade: "", //Cidade padrão

            codigo: "",
            categoria: "",
            idCategoria: "",

            marca: {},
            modelo: {},

            nextUrl: "#",
            vitrine: "empregos",

            tipoDeBusca: "",

            showLoading: true,
        };

        this.handleCodigo = this.handleCodigo.bind(this);
        this.selectCategoria = this.selectCategoria.bind(this);
        this.selectEstado = this.selectEstado.bind(this);
        this.selectCidade = this.selectCidade.bind(this);
        this.selectTipoImovel = this.selectTipoImovel.bind(this);

        this.selectFilter = this.selectFilter.bind(this);
    }

    componentDidMount() {
        const { marcasList, stateList } = this.props;

        this.props.fetchEstilos();
        this.props.fetchMarcas();
        this.props.fetchStates();
        this.setState({ showLoading: false });

        if (marcasList && stateList) {
            this.setState({ marcas: marcasList, estados: stateList });
        }
    }

    componentDidUpdate(prevProps) {
        const { estilosList, marcasList, modeloList, stateList, cityList } =
            this.props;

        if (estilosList !== prevProps.estilosList) {
            this.setState({ estilos: estilosList });
        }

        if (marcasList !== prevProps.marcasList) {
            this.setState({ marcas: marcasList });
        }
        if (modeloList !== prevProps.modeloList) {
            this.setState({ modelos: modeloList });
        }
        if (stateList !== prevProps.stateList) {
            this.setState({ estados: stateList });
        }
        if (cityList !== prevProps.cityList) {
            this.setState({ cidades: cityList });
        }
    }

    selectCategoria(value) {
        this.setState({
            categoria: value.descricao.toLowerCase(),
            idCategoria: value.valor,
        });
    }

    selectMarca(value) {
        this.setState({ marca: value });

        this.props.fetchModelos(value.valor);
    }

    selectEstado(value) {
        this.setState({ ufEstado: value.valor.toLowerCase() });

        this.props.fetchCities(value.valor, this.state.tipoDeBusca);
    }

    selectCidade(value) {
        this.setState({ cidade: `${value.valor.toLowerCase()}` });
    }

    selectTipoImovel(value) {
        this.setState({ tipoImovel: value });
    }

    handleCodigo(e) {
        e.preventDefault();

        this.setState({ codigo: e.target.value });
    }

    selectFilter(key, val) {
        this.setState({ [key]: val });
    }

    render() {
        const { codigo, showLoading, secoes } = this.state;

        return (
            <React.Fragment>
                <Helmet>
                    <title>Motos novas, seminovas e usadas - Classi</title>

                    {/* Google  */}
                    <meta
                        property="title"
                        content="Motos novas, seminovas e usadas - Classi"
                    />
                    <meta property="type" content="website" />
                    <meta property="url" content={process.env.BASE_URL} />
                    <meta
                        property="image"
                        content={process.env.BASE_URL + ClassiLogo}
                    />
                    <meta
                        property="description"
                        content="Comprar ou vender motos é no Classificados mais tradicional de Goiás. Encontre as melhores ofertas de motos novas, seminovas e usadas."
                    />
                    <meta property="site_name" content="Classi" />

                    {/* Open Graph data  */}
                    <meta
                        property="og:title"
                        content="Motos novas, seminovas e usadas - Classi"
                    />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={process.env.BASE_URL} />
                    <meta
                        property="og:image"
                        content={process.env.BASE_URL + ClassiLogo}
                    />
                    <meta
                        property="og:description"
                        content="Comprar ou vender motos é no Classificados mais tradicional de Goiás. Encontre as melhores ofertas de motos novas, seminovas e usadas."
                    />
                    <meta property="og:site_name" content="Classi" />
                    <meta property="fb:admins" content="177140826180797" />

                    {/* Twitter */}
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:site" content="@classi_br" />
                    <meta
                        name="twitter:title"
                        content="Motos novas, seminovas e usadas - Classi"
                    />
                    <meta
                        name="twitter:description"
                        content="Comprar ou vender motos é no Classificados mais tradicional de Goiás. Encontre as melhores ofertas de motos novas, seminovas e usadas."
                    />
                    <meta name="twitter:creator" content="@classi_br" />
                    <meta
                        name="twitter:image:src"
                        content={process.env.BASE_URL + ClassiLogo}
                    />
                </Helmet>

                <Container direction="column" noPadding={true}>
                    <Topbar
                        withAnchor={false}
                        backgroundColor={Theme[vitrineType].main}
                    />

                    {showLoading && <Loading />}

                    {/* Intervenção */}
                    <GPTComponent gtpId="intervencao" gpt="intervencao" />

                    <Container
                        firstContainer={true}
                        direction="row"
                        justify="center"
                    >
                        <VitrineTitulo vitrinecolor={Theme[vitrineType].main}>
                            Encontre a moto dos seus sonhos.
                        </VitrineTitulo>
                    </Container>

                    <Container backgroundImg={BgCarros} direction="row">
                        <LogoVitrine src={LogoCarros} />

                        <FilterContainer direction="column">
                            {/* Estilos */}
                            <Filter noBorder direction="row">
                                <FilterLabel>Estilos: </FilterLabel>

                                <FilterSelect
                                    placeholder="Selecione o estilo"
                                    selection
                                    getOptionLabel={(option) =>
                                        option.descricao
                                    }
                                    options={this.state.estilos}
                                    vitrinecolor={Theme[vitrineType].main}
                                    onChange={(option) =>
                                        this.selectFilter("estilo", option)
                                    }
                                />
                            </Filter>

                            {/* Marca */}
                            <Filter noBorder direction="row">
                                <FilterLabel>Marca: </FilterLabel>

                                <FilterSelect
                                    placeholder="Selecione a marca"
                                    selection
                                    getOptionLabel={(option) =>
                                        option.descricao
                                    }
                                    options={this.state.marcas}
                                    vitrinecolor={Theme[vitrineType].main}
                                    onChange={(option) =>
                                        this.selectMarca(option)
                                    }
                                />
                            </Filter>

                            {/* Modelo */}
                            <Filter noBorder direction="row">
                                <FilterLabel>Modelo: </FilterLabel>

                                <FilterSelect
                                    placeholder="Selecione a categoria"
                                    selection
                                    getOptionLabel={(option) =>
                                        option.descricao
                                    }
                                    options={this.state.modelos}
                                    vitrinecolor={Theme[vitrineType].main}
                                    onChange={(option) =>
                                        this.selectFilter("modelo", option)
                                    }
                                    isDisabled={
                                        this.state.modelos ? false : true
                                    }
                                />
                            </Filter>

                            {/* Estado/Cidade */}
                            <Filter noBorder direction="row">
                                <FilterLabel>Onde: </FilterLabel>

                                <FilterSelectLocal
                                    placeholder="Estado"
                                    search
                                    selection
                                    options={this.state.estados}
                                    vitrinecolor={Theme[vitrineType].main}
                                    getOptionLabel={(option) =>
                                        option.descricao
                                    }
                                    onChange={(option) =>
                                        this.selectEstado(option)
                                    }
                                />

                                <FilterSelectLocal
                                    placeholder="Cidade"
                                    search
                                    selection
                                    options={this.state.cidades}
                                    vitrinecolor={Theme[vitrineType].main}
                                    getOptionLabel={(option) =>
                                        option.descricao
                                    }
                                    isDisabled={
                                        this.state.cidades.length > 0
                                            ? false
                                            : true
                                    }
                                    onChange={(option) =>
                                        this.selectCidade(option)
                                    }
                                />
                            </Filter>

                            {/* Código/Busca */}
                            <Filter noBorder direction="row">
                                <FilterCodigo
                                    label="Busca por código"
                                    value={this.state.codigo}
                                    onChange={this.handleCodigo}
                                />

                                <BuscaBtn
                                    buscacolor="#ffa000"
                                    to={{
                                        pathname: `/busca${UrlsClassiUtils.constructFiltrosPathname(
                                            this.state.ufEstado,
                                            this.state.cidade,
                                            "",
                                            this.state.marca.descricao,
                                            this.state.modelo.descricao
                                        )}`,
                                        state: {
                                            tipoDeBusca: this.state.tipoDeBusca,
                                            codigo: this.state.codigo
                                                ? this.state.codigo
                                                : null,
                                        },
                                        search: `?pagina=0${
                                            codigo ? "&codigo=" + codigo : ""
                                        }${
                                            this.state.estilo
                                                ? "&estilo=" +
                                                  StringUtils.cleanString(
                                                      this.state.estilo.valor
                                                  )
                                                : ""
                                        }`,
                                    }}
                                >
                                    BUSCAR
                                </BuscaBtn>
                            </Filter>
                        </FilterContainer>
                    </Container>

                    <BannerContainer>
                        <GPTComponent gpt="small_maxiboard" />
                    </BannerContainer>
                </Container>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => ({
    estilosList: store.search.estilosList,
    marcasList: store.search.marcasList,
    modeloList: store.search.modeloList,
    stateList: store.search.stateList,
    cityList: store.search.cityList,
});

function loadData(store) {
    return store.dispatch(fetchMarcas());
}

export default {
    loadData,
    component: withRouter(
        connect(mapStateToProps, {
            fetchEstilos,
            fetchMarcas,
            fetchModelos,
            fetchStates,
            fetchCities,
        })(Home)
    ),
};
