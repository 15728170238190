import React, { Component } from "react";
import { withRouter } from "react-router";

// Redux
import { connect } from "react-redux";

// Components
import {
    Container,
    CardContainer,
    ContainerTopo,
    TituloBusca,
    ContainerTitulo,
} from "./../../components/commons/theme-styled";

import CardAnuncio from "./../../components/card-anuncio/card-anuncio";
import Paginacao from "./../../components/paginacao/paginacao";
import Ordenacao from "./../../components/ordenacao/ordenacao";
import FiltroBusca from "./../../components/filtro/Filtros";
import Hidden from "@material-ui/core/Hidden";
import Topbar from "./../../components/topbar/topbar";
import Loading from "../../components/loading/loading";
import GPTComponent from "./../../components/gpt/GPT";
import { Helmet } from "react-helmet";

// Colors
import { Theme } from "./../../components/commons/vitrine-colors";

// Images
import ClassiLogo from "./../../resources/svg/logo-classi.svg";

// Actions
import {
    fetchMarcas,
    fetchModelos,
    fetchAnunciosList,
    getUser,
} from "./../../actions";

// Mockups
import { mockupFiltro } from "./../../resources/mockups/filtro-mockup";
import { UrlsClassiUtils } from "./../../resources/methods/UrlsClassiUtils";
import { StringUtils } from "./../../resources/methods/StringUtils";
import NoResults from "../../components/no-results/noResults";

const vitrineType = process.env.VITRINE_TYPE.toLowerCase();

class Busca extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filtro: {
                codigo: null,
                ordenacao: "RELEVANCIA",
                marca: null,
                modelo: null,
                pagina: 0,
                paginado: true,
                estado: null,
                cidade: null,
                qtdRegistros: 12,
            },
            presetFiltro: {
                marca: null,
                modelo: null,
                estado: null,
                cidade: null,
                codigo: null,
            },
            showLoading: true,
        };
    }

    async componentDidMount() {
        try {
            const { categorias, marcas, modelos } = this.props.searchFilterData;
            let firstFilter = this.state.filtro;
            let marcasList, modelosList, secaoId, marcaId, modeloId;

            //Utiliza-se a URL para definir preset de filtro, porém é necessário correlacionar o label na URL com o ID correspondente
            let marca = UrlsClassiUtils.getMarca(this.props.location.pathname);
            let modelo = UrlsClassiUtils.getModelo(
                this.props.location.pathname
            );
            let estado = UrlsClassiUtils.getEstado(
                this.props.location.pathname
            );
            estado = estado != "brasil" ? estado.toUpperCase() : null;

            let cidade = UrlsClassiUtils.getCidade(
                this.props.location.pathname
            );
            let codigo = UrlsClassiUtils.getCodigo(this.props.location.search);

            // Pega do search
            const search = this.props.location.search;
            const params = new URLSearchParams(search);
            let estilo = params.get("estilo");

            // Pega o id das listas a partir dos parametros da url
            if (this.props.searchFilterData.length == 0) {
                marcasList = await this.props
                    .fetchMarcas()
                    .then((response) => response);

                if (marca) {
                    marcaId = marcasList.filter(
                        (item) =>
                            StringUtils.cleanString(
                                item.descricao
                            ).toLowerCase() == marca
                    )[0].valor;
                }

                if (modelo) {
                    modelosList = await this.props
                        .fetchModelos(marcaId)
                        .then((response) => response);
                    modeloId = modelosList.filter(
                        (item) =>
                            StringUtils.cleanString(
                                item.descricao
                            ).toLowerCase() == modelo
                    )[0].valor;
                }
            } else {
                marcasList = await this.props
                    .fetchMarcas()
                    .then((response) => response);

                if (marca) {
                    marcaId = marcasList.filter(
                        (item) =>
                            StringUtils.cleanString(
                                item.descricao
                            ).toLowerCase() == marca
                    )[0]
                        ? marcasList.filter(
                              (item) =>
                                  StringUtils.cleanString(
                                      item.descricao
                                  ).toLowerCase() == marca
                          )[0].valor
                        : null;
                }

                if (modelo && marcaId) {
                    modelosList = await this.props
                        .fetchModelos(marcaId)
                        .then((response) => response);
                    modeloId = modelosList.filter(
                        (item) =>
                            StringUtils.cleanString(
                                item.descricao
                            ).toLowerCase() == modelo
                    )[0]
                        ? modelosList.filter(
                              (item) =>
                                  StringUtils.cleanString(
                                      item.descricao
                                  ).toLowerCase() == modelo
                          )[0].valor
                        : null;
                }
            }

            firstFilter.marca = marcaId;
            firstFilter.modelo = modeloId;
            firstFilter.estado = estado;
            firstFilter.cidade = cidade;
            firstFilter.codigo = codigo;
            firstFilter.estilo = estilo;

            let hasPresetFiltro = Boolean(
                marcaId || modeloId || estado || cidade || codigo || estilo
            );

            const anunciosList = await this.props.fetchAnunciosList(
                firstFilter
            );

            this.setState({
                presetFiltro: firstFilter,
                showLoading: false,
                hasPresetFiltro: hasPresetFiltro,
            });
        } catch (error) {
            console.log(error);
        }
    }

    checkForPresetFilter() {
        //Utiliza-se a URL para definir preset de filtro, porém é necessário correlacionar o label na URL com o ID correspondente
        let estado = UrlsClassiUtils.getEstado(this.props.location.pathname);
        let cidade = UrlsClassiUtils.getCidade(this.props.location.pathname);
        let marca = UrlsClassiUtils.getMarca(this.props.location.pathname);
        let modelo = UrlsClassiUtils.getModelo(this.props.location.pathname);
        let codigo = UrlsClassiUtils.getCodigo(this.props.location.search);

        let hasPresetFiltro = Boolean(
            marca || modelo || estado || cidade || codigo || estilo
        );

        if (hasPresetFiltro) {
            this.setState({
                presetFiltro: {
                    estilo,
                    marca,
                    modelo,
                    estado,
                    cidade,
                },
                hasPresetFiltro: hasPresetFiltro,
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { searchFilterData, authentication } = this.props;

        //Atualiza lista de anúncios em caso de mudança de usuários.
        if (prevProps.authentication.userEmail != authentication.userEmail) {
            this.props.fetchAnunciosList(this.state.filtro);
        } else if (prevProps.searchFilterData != searchFilterData) {
            this.topContainer.scrollIntoView({ behavior: "smooth" });
            this.setState({
                showLoading: false,
            });
        } else if (this.state.hasPresetFiltro) {
            this.setState({ hasPresetFiltro: false });
        }
    }

    // TODO: melhorar busca de filtro
    handleNewPlaybills = (key, value) => {
        const { filtro } = this.state;

        let newFilter = filtro;

        // Condição aplicada caso o componente seja duplo, por exemplo o filtro de Mínimo e Máximo
        if (Array.isArray(key)) {
            key.map((item, index) => {
                newFilter[item] = parseInt(value[index]);
            });

            this.setState({
                filtro: newFilter,
                showLoading: true,
                hasPresetFiltro: false,
            });
            this.props.fetchAnunciosList(newFilter);
        } else if (newFilter[key] !== value) {
            newFilter[key] = value || value === 0 ? value : null;

            switch (key) {
                case "estado":
                    newFilter["cidade"] = null;
                    break;
                case "estilo":
                    newFilter["marca"] = null;
                    newFilter["modelo"] = null;
                    break;
                case "marca":
                    newFilter["modelo"] = null;
                    break;
            }

            if (key !== "pagina") {
                newFilter["pagina"] = 0; //Toda alteração de filtro, menos de página, reseta a paginação
            }

            if (key === "anunciante") {
                newFilter["anunciante"] = value ? value.valor : null;
                newFilter["anuncianteNome"] = value
                    ? value.descricao
                    : "estoque";
            }

            this.setNewUrl(newFilter);

            if (this.topContainer) {
                this.topContainer.scrollIntoView({ behavior: "smooth" });
            }

            this.setState({
                filtro: newFilter,
                showLoading: true,
                hasPresetFiltro: false,
            });
            this.props.fetchAnunciosList(newFilter);
        }
    };

    setNewUrl(filtro) {
        if (!this.state.hasPresetFiltro && this.props.searchFilterData) {
            let marcaList, modeloList;

            marcaList =
                this.props.searchFilterData.marcas || this.props.marcaList;
            modeloList =
                this.props.searchFilterData.modelos || this.props.modeloList;

            let newPathname = UrlsClassiUtils.makeNewPathname(
                this.props.location.pathname,
                filtro.estado,
                filtro.cidade,
                filtro.anuncianteNome,
                filtro.marca && marcaList
                    ? marcaList.filter((item) => item.valor == filtro.marca)[0]
                          .descricao
                    : "",
                filtro.modelo && modeloList
                    ? modeloList.filter(
                          (item) => item.valor == filtro.modelo
                      )[0].descricao
                    : ""
            );

            let newSearch = UrlsClassiUtils.makeNewSearch(
                this.props.location.search,
                filtro.query,
                filtro.pagina,
                filtro.codigo,
                false,
                StringUtils.cleanString(filtro.estilo)
            );

            window.history.pushState(
                null,
                null,
                window.location.origin +
                    "/" +
                    newPathname +
                    (newSearch ? "?" + newSearch : "")
            );
        }
    }

    geraTextoResultados() {
        let textoResultados = "";
        let marca, modelo;
        const { filtro } = this.state;

        if (filtro.marca && filtro.modelo && this.props.searchFilterData) {
            let indexMarca = this.props.searchFilterData.marcas.findIndex(
                function (o) {
                    return o.valor == filtro.marca;
                }
            );

            if (indexMarca >= 0) {
                marca = this.props.searchFilterData.marcas[indexMarca];
            }

            let indexModelo = this.props.searchFilterData.modelos.findIndex(
                function (o) {
                    return o.valor == filtro.modelo;
                }
            );

            if (indexModelo >= 0) {
                modelo = this.props.searchFilterData.modelos[indexModelo];
            }

            if (indexMarca && indexModelo) {
                textoResultados = `Resultados para moto ${marca.descricao} - ${modelo.descricao}`;

                if (filtro.cidade) {
                    textoResultados += ` em ${filtro.cidade}`;
                }
            }
        }

        return textoResultados;
    }

    dismissCarrossel = () => {
        this.setState({
            mostrarCarroseel: false,
            arrayCluster: [],
        });
    };

    handleClusterClick = (e) => {
        if (e.markers_.length > 20)
            this.setState({
                mostrarCarroseel: false,
            });
        else {
            this.setState({
                loadingCluster: true,
            });
            let arrayIds = e.markers_.map((x) => x.id);
        }
    };

    render() {
        const { anuncios } = this.props.searchFilterData;
        const { searchFilterData, authentication } = this.props;
        const { showLoading, presetFiltro, hasPresetFiltro } = this.state;

        return (
            <React.Fragment>
                <Helmet>
                    <title>Motos novas, seminovas e usadas - Classi</title>

                    {/* Google  */}
                    <meta
                        property="title"
                        content="Motos novas, seminovas e usadas - Classi"
                    />
                    <meta property="type" content="website" />
                    <meta property="url" content={process.env.BASE_URL} />
                    <meta
                        property="image"
                        content={process.env.BASE_URL + ClassiLogo}
                    />
                    <meta
                        property="description"
                        content="Comprar ou vender motos é no Classificados mais tradicional de Goiás. Encontre as melhores ofertas de motos novas, seminovas e usadas."
                    />
                    <meta property="site_name" content="Classi" />

                    {/* Open Graph data  */}
                    <meta
                        property="og:title"
                        content="Motos novas, seminovas e usadas - Classi"
                    />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={process.env.BASE_URL} />
                    <meta
                        property="og:image"
                        content={process.env.BASE_URL + ClassiLogo}
                    />
                    <meta
                        property="og:description"
                        content="Comprar ou vender motos é no Classificados mais tradicional de Goiás. Encontre as melhores ofertas de motos novas, seminovas e usadas."
                    />
                    <meta property="og:site_name" content="Classi" />
                    <meta property="fb:admins" content="177140826180797" />

                    {/* Twitter */}
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:site" content="@classi_br" />
                    <meta
                        name="twitter:title"
                        content="Motos novas, seminovas e usadas - Classi"
                    />
                    <meta
                        name="twitter:description"
                        content="Comprar ou vender motos é no Classificados mais tradicional de Goiás. Encontre as melhores ofertas de motos novas, seminovas e usadas."
                    />
                    <meta name="twitter:creator" content="@classi_br" />
                    <meta
                        name="twitter:image:src"
                        content={process.env.BASE_URL + ClassiLogo}
                    />
                </Helmet>

                <Container
                    direction="column"
                    noPadding={true}
                    ref={(el) => {
                        this.topContainer = el;
                    }}
                >
                    <Container direction="column" noPadding={true}>
                        <Topbar
                            busca
                            withAnchor={false}
                            backgroundColor={Theme[vitrineType].main}
                            filters={FiltroBusca}
                            searchFilterData={searchFilterData}
                            handleFilter={this.handleNewPlaybills}
                            presetFiltro={hasPresetFiltro ? presetFiltro : null}
                        />
                    </Container>

                    {showLoading && <Loading />}

                    {/* Intervenção */}
                    <GPTComponent gtpId="intervencao" gpt="intervencao" />

                    <ContainerTopo
                        noPadding={true}
                        firstContainer={true}
                        direction="row"
                        align="initial"
                        justify="center"
                    >
                        <Container
                            noPadding={true}
                            direction="row"
                            align="initial"
                            justify="center"
                        >
                            {!showLoading && (
                                <GPTComponent
                                    gptId="topo"
                                    key="publicidade-busca-top"
                                    gpt="small_maxiboard"
                                />
                            )}
                        </Container>
                        <Container
                            noPadding={true}
                            direction="row"
                            align="initial"
                            justify="center"
                        >
                            <ContainerTitulo
                                maxWidth="1220px"
                                justify="flex-end"
                                padding="0 10px"
                                height="55px"
                            >
                                <ContainerTitulo
                                    maxWidth="900px"
                                    justify="space-between"
                                    paddingTop={"8px"}
                                >
                                    <TituloBusca>
                                        {this.geraTextoResultados()}
                                    </TituloBusca>

                                    {anuncios && anuncios.length > 0 && (
                                        <Ordenacao
                                            vitrinecolor={
                                                Theme[vitrineType].main
                                            }
                                            handleFilter={
                                                this.handleNewPlaybills
                                            }
                                            filterName="ordenacao"
                                            sortList={
                                                searchFilterData.ordenacao
                                            }
                                        />
                                    )}
                                </ContainerTitulo>
                            </ContainerTitulo>
                        </Container>

                        <Hidden xsDown>
                            <FiltroBusca
                                searchFilterData={searchFilterData}
                                handleFilter={this.handleNewPlaybills}
                                presetFiltro={
                                    hasPresetFiltro ? presetFiltro : null
                                }
                            />
                        </Hidden>

                        {/* quando cluster é clicado a resposta é arrayCluster que entra na frente da lista de anuncios */}
                        {anuncios && anuncios.length > 0 ? (
                            <CardContainer>
                                {anuncios &&
                                    anuncios.map((anuncio, index) => {
                                        return (
                                            <div key={index}>
                                                {index !== 0 && !(index % 3) ? (
                                                    <GPTComponent
                                                        gptId={`card-${index}`}
                                                        key={`publicidade-${index}`}
                                                        gpt="megabox"
                                                    />
                                                ) : null}
                                                <CardAnuncio
                                                    key={anuncio.id}
                                                    vitrine={
                                                        anuncio.secao.value
                                                    }
                                                    vitrineNome={
                                                        anuncio.secao.label
                                                    }
                                                    titulo={anuncio.titulo}
                                                    subtitulo={
                                                        anuncio.veiculoVersao
                                                            .descricao
                                                    }
                                                    descricao={
                                                        anuncio.descricao
                                                    }
                                                    valor={
                                                        anuncio.ofertaSalarial
                                                    }
                                                    localizacao={
                                                        anuncio.localizacao
                                                            ? anuncio
                                                                  .localizacao
                                                                  .cidade +
                                                              " - " +
                                                              anuncio
                                                                  .localizacao
                                                                  .estado
                                                            : null
                                                    }
                                                    thumbnail={
                                                        anuncio.imagens &&
                                                        anuncio.imagens.length >
                                                            0
                                                            ? anuncio.imagens[0]
                                                                  .imageUrl
                                                            : null
                                                    }
                                                    url={anuncio.url}
                                                    isFavorite={
                                                        anuncio.favorito
                                                    }
                                                    idAnuncio={anuncio.id}
                                                    authentication={
                                                        authentication
                                                    }
                                                    prioridade={
                                                        anuncio.prioridade
                                                    }
                                                    categoria={
                                                        anuncio.categoriaDigital
                                                            .descricao
                                                    }
                                                    urlLogoFiliacao={
                                                        anuncio.urlLogoFiliacao
                                                    }
                                                    valorCombinar={
                                                        anuncio.valorCombinar
                                                    }
                                                    valor={anuncio.valor}
                                                    cardLogo={anuncio.marca}
                                                    anoVeiculo={{
                                                        fabricacao:
                                                            anuncio.anoFabricacao
                                                                .toString()
                                                                .slice(-2),
                                                        modelo: anuncio.anoVersao
                                                            .toString()
                                                            .slice(-2),
                                                    }}
                                                    quilometragem={
                                                        anuncio.quilometragem
                                                            ? anuncio
                                                                  .quilometragem
                                                                  .descricao
                                                            : null
                                                    }
                                                    cambio={
                                                        anuncio.tipoCambio
                                                            ? anuncio.tipoCambio
                                                                  .descricao
                                                            : null
                                                    }
                                                    cor={
                                                        anuncio.corVeiculo
                                                            ? anuncio.corVeiculo
                                                                  .descricao
                                                            : null
                                                    }
                                                />
                                            </div>
                                        );
                                    })}
                            </CardContainer>
                        ) : (
                            <CardContainer>
                                <NoResults />
                            </CardContainer>
                        )}
                    </ContainerTopo>

                    <Container direction="row" align="initial" justify="center">
                        <Paginacao
                            filterName="pagina"
                            handleFilter={this.handleNewPlaybills}
                            qtdTotalRegistros={
                                searchFilterData.qtdTotalRegistros
                            }
                            vitrinecolor={Theme[vitrineType].main}
                            paginaAtual={searchFilterData.paginaAtual}
                            qntdPaginas={searchFilterData.qtdPaginas}
                        />
                    </Container>
                </Container>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => ({
    secoesList: store.search.secoesList,
    marcasList: store.search.marcasList,
    modeloList: store.search.modeloList,
    searchFilterData: store.search.searchFilterData,
    authentication: store.authentication.authentication,
    cluster: store.cluster,
});

function loadData(store) {
    return store.dispatch(fetchAnunciosList(mockupFiltro));
}

export default {
    loadData,
    component: connect(mapStateToProps, {
        fetchMarcas,
        fetchModelos,
        fetchAnunciosList,
        getUser,
    })(withRouter(Busca)),
};
