import React from 'react';
import PropTypes from 'prop-types';

//Styles
import { FavContainer } from './favoritar-styled';
import Favorite from '@material-ui/icons/Favorite';
import Axios from 'axios';

// Favoritar.propTypes = {
//   isFavorite: PropTypes.bool.isRequired,
//   idAnuncio: PropTypes.any,
//   userEmail: PropTypes.any
// };

// Favoritar.defaultProps = {
//   isFavorite: false
// };

class Favoritar extends React.Component {
  state = {
    isFavorite: this.props.isFavorite
  }

  componentDidUpdate(prevState) {
    if (this.props.isFavorite !== prevState.isFavorite) {
      this.setState({ isFavorite: this.props.isFavorite })
    }
  }

  handleFavoritePlaybill = () => {
    const { isFavorite } = this.state;
    const { authentication, idAnuncio } = this.props;

    if (authentication.isAuthenticated) {
      let apiRequestType = '';
      // const data = {
      //   idAnuncio: parseFloat(idAnuncio),
      //   userEmail: authentication.userEmail
      // }
      const data = {
        idAnuncio: idAnuncio
      }

      if (isFavorite) {
        apiRequestType = 'desfavoritarAnuncio';
      } else {
        apiRequestType = 'favoritarAnuncio';
      }

      Axios.get(`${process.env.API_URL}/vitrine-motos/${apiRequestType}`, {
        headers: { 'Authorization': `Bearer ${window.keycloak.token}` },
        params: data
      })
        .then(response => {

          this.setState({
            isFavorite: !this.state.isFavorite
          });
        })

    } else {
      window.keycloak.login()
    }
  }

  render() {
    const { isFavorite } = this.state;
    const { isAnuncioPage } = this.props;
    const { isTopAnunciePage } = this.props;

    return (
      <FavContainer favorite={isFavorite} isAnuncioPage={isAnuncioPage} className={`${isTopAnunciePage ? 'top-anuncie' : ''}`}>
        <Favorite
          className='fav-icon'
          onClick={this.handleFavoritePlaybill}
        />
      </FavContainer>
    );
  };
}

export default Favoritar;

 // componentDidMount() {
  //   this.state.isFavorite = this.props.isFavorite;

  //   this.setState({
  //     isFavorite: this.props.isFavorite,
  //     userEmail: this.props.userEmail
  //   });
  // }

  // clickFavoritar() {
  //   if (this.state.isFavorite && this.state.isAuthenticated) {
  //     this.desfavoritarAnuncio();
  //   } else {
  //     this.favoritarAnuncio();
  //   }
  // }

  // desfavoritarAnuncio() {
  //   // TODO: Remover email de teste
  //   const data = {
  //     idAnuncio: this.props.idAnuncio
  //   }

  //   Axios.get('/api/desfavoritarAnuncio', {
  //       params: data
  //     })
  //     .then((response) => {
  //       this.setState({
  //         isFavorite: false
  //       });
  //     })
  // }

  // favoritarAnuncio() {
  //   // TODO: Remover email de teste
  //   const data = {
  //     idAnuncio: this.props.idAnuncio
  //   }

  //   Axios.get('/api/favoritarAnuncio', {
  //       params: data
  //     })
  //     .then((response) => {
  //       this.setState({
  //         isFavorite: true
  //       });
  //     })
  // }
